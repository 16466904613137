var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"c2d1f35a0bcf4f03fa586a5933d9cfe06d590d22"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import posthog from 'posthog-js';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_PROJECT_ID = Number.parseInt(process.env.NEXT_PUBLIC_SENTRY_PROJECT_ID || '0', 10);

Sentry.init({
  dsn: SENTRY_DSN || 'https://5d7c43ef1c564a799a6c9b86e5b8a3ec@o4505002465099776.ingest.sentry.io/4505002484563968',
  // Adjust this value in production, or use tracesSampler for greater control
  // tracesSampleRate: 1.0,
  enableTracing: false,
  environment: process.env.NODE_ENV,
  integrations: [new posthog.SentryIntegration(posthog, 'inkeep', SENTRY_PROJECT_ID)],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
